<template>
    <div class="container_con">
        <el-card>
            <div class="search">
                <div>
                    <el-button type="primary" @click="$router.push('/addItem')" v-if="have_auth('/system/add')">+ 新增</el-button>
                    <el-button type="primary" icon="el-icon-s-delete" :disabled="multiple" @click="handleDelete"  v-if="have_auth('/system/del')">删除</el-button>
                </div>
                <div>
                    <el-select @change="sele_change" v-model="queryParams.groups" placeholder="请选择分组" style="width:200px; margin-right: 10px;" clearable>
                        <el-option :label="item" v-for="(item,index) in groups" :key="index"
                            :value="item"></el-option>
                    </el-select>
                    <el-input icon="el-icon-search" v-model="queryParams.title" @keyup.enter.native="handleQuery" placeholder="请输入搜索内容" clearable
                        style="width:300px;margin-right:10px"></el-input>
                    <el-button type="primary" @click="handleQuery()">搜索</el-button>
                </div>
            </div>
            <div style="margin-top:20px">
                <el-table :data="list" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column prop="title" label="标题" />
                    <el-table-column prop="name" label="配置名称标识" />
                    <el-table-column prop="value" label="值" />
                    <el-table-column prop="groups" label="分组" />
                    <el-table-column fixed="right" label="操作" width="150">
                        <template v-slot="scope">
                            <el-button type="text" size="small" @click="handleUpdate(scope.row)"  v-if="have_auth('/system/edit')">编辑</el-button>
                            <!-- <el-button type="text" size="small" @click="handleDelete(scope.row)"  v-if="have_auth('/system/del')">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
</template>

<script>
    export default {
        name: "department",
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
					groups: '',
                    title: ''
                },
                total:0,
                list:[],
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                o_list:[],
                groups:[]
            };
        },
        created() {
			this.getGroupList();
            this.getList();
        },
        methods: {
            sele_change(e){
                this.getList()
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
              this.ids = selection.map(item => item.id);
              this.single = selection.length != 1;
              this.multiple = !selection.length;
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
              this.$router.push('/addItem?id='+row.id)
            },
            /** 删除按钮操作 */
            handleDelete(row) {
              const _this=this;
              const ids = row.id || this.ids;
              this.$confirm('是否确认删除?', "警告", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(function() {
                  _this.$httpGet("/backend/SysConfig/del", {ids:ids}).then((res) => {
                      if (res.status == 200) {
                          _this.getList();
                          _this.$message.success(res.message);
                      } else {
                          _this.$message.error(res.message);
                      }
                  }).catch((err) => {
                      console.log(err);
                  });
                })
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                this.$httpGet("/backend/SysConfig/index", this.queryParams).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
			getGroupList() {
				this.$httpGet("/backend/SysConfig/groupList").then((res) => {
				    if (res.status == 200) {
				        this.groups = res.data;
				    } else {
				        this.$message.error(res.message);
				    }
				}).catch((err) => {
				    console.log(err);
				});
			},
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>

<style lang="scss" scoped></style>
